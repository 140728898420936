@use '../../styles/yr' as *;

.weather-location-list-edit-panel {
  padding: size(2);
}

.weather-location-list-edit-panel__menu-item {
  & + & {
    margin-top: size(2);
  }
}

.weather-location-list-edit-panel__menu-button {
  width: 100%;
}
