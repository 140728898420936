@use './theme' as *;
@use '../functions/rem' as *;
@use '../functions/size' as *;

// TODO(scb): Are all these necessary and if so are they correct?
$ease-in: cubic-bezier(0.25, 0.5, 0.25, 0.75);
$ease-expo-out: cubic-bezier(0.19, 1, 0.22, 1);
$ease-in-out: bezier-curve(0.9, 0, 0.1, 1);
$ease-circ-in-out: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$ease-cubic-out: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-in-out-quad: cubic-bezier(0.5, 0, 0.5, 0.9);
$menu-dialog-ease-in: cubic-bezier(0.74, 0.01, 0.69, 0.97);
$ui-scroll-shadow-transition: 50ms $ease-circ-in-out;
$ui-transition: 150ms $ease-circ-in-out;
$ui-transition-slow: 500ms $ease-circ-in-out;
$notification-transition: 200ms $ease-in-out-quad;
$opacity-transition: 150ms ease-out;
$divider-solid: 1px solid var(--color-stroke-subtle-core-blue);
$border-radius: rem(2px);
$accessible-button-target-size: 44px; // AAA button target size, see https://www.w3.org/TR/WCAG21/#target-size

// Layout
$layout-size-horizontal: 2;
$layout-width: rem(1280px);
$layout-width-including-spacing: $layout-width + 2 * size($layout-size-horizontal);
$grid-gutter: size(2);

// Z-indexes
$z-index-background-image: -1; // Render behind all other elements
$z-index-sticky-header: 10;
$z-index-statistics-toolbar: 20;
$z-index-search: 30;
$z-index-search-input: 40;
$z-index-sticky-heading: 50;
$z-index-map-element: 60;
$z-index-map-element-time-tray: 70;
$z-index-popover: 80;
$z-index-search-suggestions: 85;
$z-index-clippy: 90;
$z-index-map-popup: 100;
$z-index-snack: 110;
$z-index-survey: 120;
$z-index-app-status: 130;
$z-index-modal-dialog: 140;
$z-index-animatable-modal: 150;
