@use '../../styles/yr' as *;

.empty-text {
  background-color: var(--color-skeleton-base);
  display: inline-block;
  width: 100%;
  height: 1em;

  &[data-animation='true'] {
    // TODO(as): Untill we figure out how we use rgba values and gradients from yr-design-tokens, we define them here.
    background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.8), rgba(#fff, 0));
    &[data-theme='dark'] {
      background-image: linear-gradient(
        90deg,
        rgba($color-core-blue-600, 0),
        rgba($color-core-blue-600, 0.4),
        rgba($color-core-blue-600, 0)
      );
    }
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1.2s ease infinite;
  }
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
