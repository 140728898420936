@use '../../styles/yr' as *;

$border-size: rem(2px);

.weather-location-list-item {
  background-color: var(--color-background-base);
  border: 2px solid transparent;
  border-radius: size(1);
  box-shadow: $theme-box-shadow;

  // Make z index of descendants local
  position: relative;
  z-index: 0;

  // We make sure the hover styles is only added when user agen supports it
  // or the user is using an external pointer of some sorts
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      transition: all 100ms ease-out;
      border-color: var(--color-stroke-subtle-core-blue);
    }
  }

  // touch screens should get a background-color when touching the list-item
  &:active {
    border-color: var(--color-stroke-subtle-core-blue);
  }
}

.weather-location-list-item__container {
  display: grid;
  height: size(9) - (2 * $border-size);
  padding-left: size(2);

  @include mq-lt($mq-990) {
    height: size(12) - (2 * $border-size);
  }

  @include mq-gte($mq-990) {
    grid-template-areas: 'location location warnings forecast edit';
    grid-template-columns: auto rem(20) 1fr 45% rem(50);
    grid-template-rows: 1fr;
  }

  @include mq-lt($mq-990) {
    grid-template-areas:
      'location location location . edit'
      'warnings forecast forecast forecast .';
    grid-template-columns: 1fr 1fr 1fr rem(20) rem(50);
    grid-template-rows: 1fr 1fr;
  }

  @include mq-lte($mq-450) {
    grid-template-areas:
      'location location location . edit'
      'warnings forecast forecast forecast forecast';
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.weather-location-list-item__location {
  grid-area: location;
  display: grid;
  align-self: center;

  grid-template-areas: 'location-name star';
  grid-template-columns: auto 1fr;

  @include mq-gte($mq-1350) {
    grid-template-areas: 'star location-name';
    gap: size(1);
  }
}

.weather-location-list-item__location-heading {
  grid-area: location-name;
  align-self: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.weather-location-list-item__location-name {
  position: relative;

  // Render in front of the location link
  z-index: 2;
}

.weather-location-list-item__location-link {
  text-decoration: none;
  // Prevent the tap-highlight on li-element from making this invisible while tapping
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  // We use a pseudo-element to make the link target
  // cover the entire list item.
  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    // Render in front of most of the content in the list item
    z-index: 1;
  }

  // We remove the default focus style from the link itself
  // and add it to the larger pseudo-element itself so the whole
  // list item gets a focus outline when the location link is focused.
  &:focus {
    outline: 0;

    &:after {
      @include focus-style;
    }
  }
}

.weather-location-list-item__star {
  display: inline-block;
  align-self: center;
  color: var(--color-stroke-subtle);
  font-weight: $typography-font-weight-bold;
  height: size(2);
  width: size(2);

  // Render in front of the location link
  z-index: 2;

  svg {
    width: 105%;
    height: 105%;
  }

  @include mq-lt($mq-1350) {
    position: relative;
    grid-area: star;
    margin-left: size(0.5);
    // The star should not be clickable when it is rendered inside the list
    pointer-events: none;
  }
}

.weather-location-list-item__star--filled {
  color: var(--color-fill-selected);
}

.weather-location-list-item__warnings {
  align-self: center;
  grid-area: warnings;
  margin: 0 size(2) 0 size(2);
  position: relative;

  display: flex;
  align-items: center;

  @include mq-lt($mq-675) {
    align-self: start;
    margin: 0;
  }
}

.weather-location-list-item__forecast {
  align-self: center;
  grid-area: forecast;
  display: flex;
  justify-content: space-between;

  @include mq-lt($mq-675) {
    align-self: start;
  }

  @include mq-lt($mq-450) {
    padding-right: size(2);
  }
}

.weather-location-list-item__forecast-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .temperature {
    margin-left: size(1);
    min-width: 40px;
    text-align: left;

    @include mq-lte($mq-450) {
      min-width: size(3);
    }
  }
  .weather-symbol {
    min-width: 30px;
    height: size(5);
    width: size(5);
  }

  &:last-child {
    @include mq-lt($mq-990) {
      display: none;
    }
  }
}

// This is a bit hacky, but the way the weather location list is styled
// each forecast wrapper must have roughly the same width in order for the
// flex items to be spread out correctly. Ideally we would use grid to make
// this more robust but for now this hack is necessary.
.weather-location-list-item__forecast-wrapper--empty {
  min-width: rem(88px);
}

.weather-location-list-item__edit-button {
  color: var(--color-fill-subtle);
  grid-area: edit;
  align-self: center;
  justify-self: center;
  position: relative;
  width: size(4);
  height: size(4);
  display: flex;
  justify-content: center;
  align-items: center;

  // Render in front of the location link
  z-index: 2;
}
